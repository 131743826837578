import { Component, PropsWithChildren } from 'react';

import TOSViewerStyles from '~/styles/tos_viewer_styles';

class TOSViewer extends Component<PropsWithChildren> {
	public render() {
		return <TOSViewerStyles>{this.props.children}</TOSViewerStyles>;
	}
}

export default TOSViewer;
