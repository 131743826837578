import classNames from 'classnames';
import { Component, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

interface Props {
	newProps?: string;
	disabled?: boolean;
	hidden?: boolean;
	loading?: boolean;
	tabIndex?: number;
	className?: string;
	onClick?: () => void;
	onClickDisabled?: () => void;
}

class Button extends Component<PropsWithChildren<Props>> {
	static defaultProps = {
		tabIndex: 0
	};

	render() {
		const { hidden, loading, disabled, onClick, className, tabIndex, onClickDisabled } = this.props;
		if (hidden) {
			return null;
		}

		const props: Pick<Props, 'tabIndex' | 'onClick'> = {
			tabIndex
		};

		if (onClick && !disabled && !loading) {
			props.onClick = onClick;
		}

		if (onClickDisabled && disabled && !loading) {
			props.onClick = onClickDisabled;
		}

		return (
			<ButtonStyles className={classNames(className, 'continue-button')}>
				<button className={classNames('button', { disabled: disabled || loading })} {...props}>
					{loading ? 'Loading' : this.props.children}
				</button>
			</ButtonStyles>
		);
	}
}

const ButtonStyles = styled.div`
	display: inline-flex;
	align-items: center;
	font-family: Helvetica Neue, Arial, sans-serif;
	text-align: center;
	button {
		background-color: white;
		cursor: pointer;
		min-width: 120px;
		flex-shrink: 0;
		width: 100%;
		border-radius: 0;
		user-select: none;
		height: 58px;
		color: white;
		text-align: center;
		display: block;
		justify-content: center;
		font-size: 16px;
		align-items: center;
		letter-spacing: 2.5px;
		text-transform: uppercase;
		border: 2px solid #bdd63a;
		color: #373737;
		font-weight: bold;
		transition: background-color 100ms cubic-bezier(0.15, 0.82, 0.38, 0.95);
		:hover {
			background-color: #bdd63a;
		}

		&.disabled {
			border: none;
			background-color: #eeeeee;
			color: #6b6b6b;
		}
	}

	.enter-hint {
		color: #6c6c6c;
		text-align: center;
		display: inline-flex;
		flex-direction: column;
		margin-left: 28px;
		line-height: 1.5rem;
		div {
			display: inline-block;
		}
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
		margin-top: 16px;
		button {
			font-size: 12px;
			display: inline-flex;
			width: 164px;
			border-radius: 28px;
		}
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.small}) {
		button {
			background-color: #bdd63a;
		}
	}
`;

export default connect()(Button);
