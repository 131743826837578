import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '~/components/input';
import { RootState } from '~/store';
import * as appActions from '~/store/app/actions';
import { selectIsLoadingAsync } from '~/store/selectors';

const mapStateToProps = (state: RootState) => ({
	loading: state.app.submittingSubscription,
	hidden: false,
	disabled: !state.app.canContinue || selectIsLoadingAsync(state)
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			onClick: appActions.appContinue,
			onClickDisabled: appActions.appDisabledContinueClick
		},
		dispatch
	);

/**
 * Standard button, connected to store to listen to appState
 */
const AppButton = connect(mapStateToProps, mapDispatchToProps)(Button);

export default AppButton;
