import { Component, PropsWithChildren } from 'react';
import styled from 'styled-components';
import ErrorBlurb from '~/components/error_blurb';
import { logError } from '~/rollbar';

export interface State {
	error: Error | null;
	errorUUID?: string;
}

class ErrorBoundary extends Component<PropsWithChildren<{ initialError?: Error }>, State> {
	constructor(props) {
		super(props);
		if (props.initialError != null) {
			this.state = {
				error: props.initialError,
				errorUUID: logError(props.initialError)?.uuid || 'No error UUID provided'
			};
		} else {
			this.state = { error: null };
		}
	}

	componentDidCatch(error, info) {
		const errorUUID = logError(error)?.uuid || 'No error UUID provided';
		this.setState({
			error,
			errorUUID
		});
	}

	render() {
		if (this.state.error) {
			return (
				<ErrorBoundaryStyles className="error-boundary">
					<h2>Something went wrong</h2>
					<ErrorBlurb />
					<hr />
					<p>Error ID: {this.state.errorUUID}</p>
				</ErrorBoundaryStyles>
			);
		}
		return this.props.children;
	}
}

const ErrorBoundaryStyles = styled.div`
	padding-top: 18px;
	padding-left: 12px;
	padding-right: 12px;
	font-size: 1.2rem;
`;

export default ErrorBoundary;
