import React from 'react';

import Button from '~/components/input/Button';
import PathStepStyles from '~/styles/path_step_styles';
import { FinalizationResponseSuccess, StepPropsWithCoursePresentInAppConfig } from '~/types';

export interface Props extends StepPropsWithCoursePresentInAppConfig {}

export interface State {}

export interface SuccessStepConfigParams {
	header: string;
	success_tips: [string];
	footer: string;
}

const default_points = [
	'Start at the beginning and work through the pages in order. This webtext is customized for your course.',
	'Complete all the questions. The questions help you make sure you’re getting what you need out of each page.'
];

class SuccessStep extends React.Component<Props, State> {
	componentDidMount() {
		this.props.setCanContinue(true);
		const course_id: any = this.props.appConfig.course.id;
		if (course_id && window.sessionStorage) {
			try {
				window.sessionStorage.setItem('soomoCourseId', course_id);
			} catch (error) {}
		}
	}

	goToText = () => {
		let jwt: string = (
			this.props.user_state.subscriptionFinalization as FinalizationResponseSuccess
		).token;

		let course_id: any = this.props.appConfig.course.id;
		if (!course_id && window.sessionStorage) {
			try {
				course_id = window.sessionStorage.getItem('soomoCourseId');
			} catch (error) {}
		}

		const path = course_id ? `/courses/${course_id}/access` : '/courses';

		window.location.href = `https://${import.meta.env.VITE_SOOMO_CORE_API_HOST}${path}?jwt=${jwt}`;
	};

	public render() {
		const { stepConfig, appConfig } = this.props;
		const params: SuccessStepConfigParams = stepConfig.params;
		const custom_points = appConfig.path_config.success_points;
		const custom_message = appConfig.path_config.success_message;
		return (
			<PathStepStyles>
				<div className="page-content">
					<h1>{stepConfig.title}</h1>
					<hr />
					<p>Tips for success:</p>
					<ol>
						{(custom_points || default_points).map((sm, i) => (
							<li key={i}>{sm}</li>
						))}
					</ol>
					{custom_message ? (
						<p>{custom_message}</p>
					) : (
						<p>
							Use the Soomo Messenger button in the lower right corner to reach out if you need us.
							We’re here to help!
						</p>
					)}
				</div>
				<Button onClick={this.goToText}>Sign Me In</Button>
			</PathStepStyles>
		);
	}
}

export default SuccessStep;
