import classNames from 'classnames';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { RootState } from '~/store';
import { SelectOption } from '~/types';
import { useBasicInputs } from '~/utils/helpers';
import NativeSelect from './NativeSelect';
import SelectInputStyles from './styles';

export interface SelectInputProps {
	options: SelectOption[];
	name?: string;
	label?: string;
	placeholder?: string;
	className?: string;
	tabIndex?: number;
	autoFocus?: boolean;
	narrow?: boolean;
	isClearable?: boolean;
	value?: any; // String matching the "value" field on one of the passed in SelectOptions
	inputValue?: string; // Searchable field value
	isSearchable?: boolean;
	hideInFullstory?: boolean;
	showNativeFallback?: boolean;
	forceNativeFallback?: boolean;
	blurInputOnSelect?: boolean;
	onInputChange?: (value: any) => any;
	onChange?: (value: any) => any; // Receives a value string from the option in the options field
	wrappingElement?: any;
	reactSelectStyles?: {
		[name: string]: (base: any) => { [property: string]: string };
	};
}

interface State {
	open: boolean;
	selectIndex: number;
	mouseOver: boolean;
}

const reactSelectStyles = {
	container: (base) => ({
		...base,
		height: 32
	}),
	valueContainer: (base) => ({
		...base,
		flex: '1 0 0%',
		flexDirection: 'column'
	}),
	control: (base) => ({
		...base,
		flex: '1 0 auto'
	}),
	singleValue: (base) => ({
		...base,
		display: 'block'
	})
};

/**
 * Default: Creates a custom select input using react-select2 as a base
 *
 * showNativeFallback: Uses native select element with hidden select and
 *  overlayed elements styled to look like the react-select element.
 *  Used to get a native select input on mobile devices.
 *
 * forceNativeFallback: Uses a native select element with no custom styles or overlays.
 * Used to support legacy browsers without styling headaches.
 */

class SelectInput extends React.Component<SelectInputProps, State> {
	static defaultProps = {
		isClearable: false,
		isSearchable: false,
		showNativeFallback: false,
		forceNativeFallback: false,
		blueInputOnSelect: false,
		hideInFullstory: false,
		autoFocus: false,
		wrappingElement: SelectInputStyles,
		reactSelectStyles
	};

	public render() {
		// Find the appropriate SelectOption to provide to react-select
		const value = this.props.options.find((o) => o.value === this.props.value);
		const { showNativeFallback, forceNativeFallback, label } = this.props;
		const classes = classNames(
			{ narrow: this.props.narrow },
			{ 'show-native-fallback': showNativeFallback },
			{ 'force-native-fallback': forceNativeFallback }
		);
		const RootElement = this.props.wrappingElement;
		return (
			<RootElement className={classes}>
				{label && <Label htmlFor={this.props.name}>{label}</Label>}
				{!forceNativeFallback && (
					<Select
						{...this.props}
						backspaceRemovesValue={this.props.isClearable}
						value={value}
						menuShouldScrollIntoView={true}
						menuPlacement={'auto'} // Calculates best fit for menu if no space is available
						menuPortalTarget={document.getElementsByTagName('body')[0]} // Places custom dropdown over app content
						onChange={(v) => this.props.onChange?.(v?.value)} // Only return the string value, not the SelectOption
						// Appends style prefix to all sub-component class names
						classNamePrefix={
							this.props.hideInFullstory ? 'fs-exclude soomo-select' : 'soomo-select'
						}
						styles={this.props.reactSelectStyles}
					/>
				)}
				{showNativeFallback || forceNativeFallback ? (
					<NativeSelect
						disableCustomStyling={forceNativeFallback}
						options={this.props.options}
						value={this.props.value}
						onChange={this.props.onChange}
						placeholder={this.props.placeholder}
					/>
				) : null}
			</RootElement>
		);
	}
}

const Label = styled.label`
	font-weight: bold;
	color: #757575;
	text-transform: uppercase;
	letter-spacing: 1.9px;
	font-size: ${({ theme }) => theme.textInput.labelSize};
	margin-bottom: 2px;
`;

export default connect((state: RootState) => ({
	forceNativeFallback: useBasicInputs(state.app.config)
}))(SelectInput);
