import React from 'react';
import styled from 'styled-components';

interface Props {}

const Spinner: React.FC<Props> = () => (
	<SpinnerStyles>
		<div id="spinner"></div>
	</SpinnerStyles>
);

const SpinnerStyles = styled.div`
	margin: auto;
	#spinner {
		display: inline-block;
		width: 64px;
		height: 64px;
		box-sizing: content-box;
	}

	#spinner:after {
		content: ' ';
		display: block;
		width: 46px;
		height: 46px;
		margin: 1px;
		border-radius: 50%;
		border: 5px solid #bdd63a;
		border-color: #bdd63a transparent #bdd63a transparent;
		animation: spinner 1.2s linear infinite;
		box-sizing: content-box;
	}
	@keyframes spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export default Spinner;
